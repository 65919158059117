<template>
  <vue-slider
    ref="priceRangeSlider"
    :min="min"
    :max="max"
    :dotSize="16"
    tooltip="hover"
    :tooltip-placement="'bottom'"
    :enable-cross="false"
    @drag-end="handleChange"
  ></vue-slider>
</template>

<script>
import VueSlider from "vue-slider-component";

export default {
  name: "Slider",
  components: {
    VueSlider,
  },
  mounted() {
    if (this.value[1] <= this.$refs.priceRangeSlider.$options.propsData.max) {
      this.$refs.priceRangeSlider.setValue(this.value);
    }
  },
  updated() {
    this.$refs.priceRangeSlider.setValue(this.value);
  },
  props: {
    min: {
      required: true,
    },
    max: {
      required: true,
    },
    value: {
      required: true,
    },
  },
  methods: {
    handleChange() {
      this.$emit("input", this.$refs.priceRangeSlider.getValue());
    },
  },
};
</script>
